import React from "react";
import styles from "./hero.module.css";
import { HeadingHeroAnimation } from "../../animation/headingHeroAnimation";
import { useSwiperContext } from "../../../context/swiperContext";
import { useLanguage } from "../../../context/languageContext";

export const Hero = ({ isActive }) => {
  const { goToSlide } = useSwiperContext();
  const { currentTextJson } = useLanguage();

  return (
    <>
      <section id="hero" class={styles.hero}>
        <div class={styles.wave}>
          <h1>
            {currentTextJson?.hero?.title.map((title) => (
              <HeadingHeroAnimation
                words={title.word}
                count={title.count}
                hasDot={title.hasDot}
              />
            ))}
          </h1>
          <div>
            <div
              onClick={() => goToSlide("Expertises", 2)}
              className={styles.openButton}
            >
              {currentTextJson?.hero?.buttonText}
            </div>
          </div>
        </div>
        <div class={styles.loghi}>
          <div>
            <img alt="TacticalPeople logo" src="./media/tactical_logo.png" />
          </div>
          <div>
            <img alt="NTTData logo" src="./media/NTT-Data-Logo.png" />
          </div>
          <div>
            <img style={{}} alt="Reply logo" src="./media/Logo_Reply.png" />
          </div>
          <div>
            <img alt="accenture logo" src="./media/ACN_WHITE_logo.png" />
          </div>
          <div>
            <img alt="AWS logo" src="./media/aws_logo.webp" />
          </div>
        </div>
      </section>
    </>
  );
};
