// CustomContext.js
import React, { createContext, useContext, useState } from "react";

const SwiperContext = createContext();

export const SwiperProvider = ({ children }) => {
  const [swiper, setSwiper] = useState(null);

  const setSwiperInstance = (swiperInstance) => {
    setSwiper(swiperInstance);
  };
  const goToSlide = (target, index) => {
    if (swiper) {
      swiper.slideTo(index);
      window.location.href = "#" + target;
    }
  };

  return (
    <SwiperContext.Provider value={{ goToSlide, swiper, setSwiperInstance }}>
      {children}
    </SwiperContext.Provider>
  );
};

export const useSwiperContext = () => {
  const context = useContext(SwiperContext);
  if (!context) {
    throw new Error("useSwiperContext must be used within a SwiperProvider");
  }
  return context;
};
