import { useNavigate } from "react-router-dom";
import { OffCanvas } from "../../../components/layout/offCanvas";
import "./nopage.css";
const NoPage = () => {
  const navigate = useNavigate();
  return (
    <div className="container_noPage">
      <h1>404</h1>
      <p>Oops! Something is wrong.</p>
      <a class="button" href="#" onClick={() => navigate("/")}>
        <i class="icon-home"></i> Go back in initial page, is better.
      </a>
    </div>
  );
};

export default NoPage;
