import { motion, easeInOut } from "framer-motion";
import styles from "./headingHeroAnimation.module.css";
export const HeadingHeroAnimation = ({ words, count, hasDot, colored }) => {
  return (
    <div className={styles.words_container} key={words}>
      {words != null && words != ""
        ? words.split(" ").map((word, index) => {
            return (
              <motion.div
                key={word + index + count}
                initial={{ translateY: "0%", opacity: 1 }}
                animate={{
                  translateY: ["40%", "0%"],
                  opacity: [0, 1],
                  transition: {
                    duration: 0.5,
                    ease: easeInOut,
                    delay: count
                      ? (count / 1.4) * 0.15 + (index / 1.4) * 0.15
                      : (index / 1.4) * 0.15,
                  },
                }}
                className={colored ? styles.word_colored : styles.word}
              >
                {word}
                {hasDot && index === words.split(" ").length - 1 ? (
                  <span className={styles.dot}>.</span>
                ) : (
                  ""
                )}
              </motion.div>
            );
          })
        : ""}
    </div>
  );
};
