import React, { useEffect, useState } from "react";

import styles from "./layout.module.css";

import { useSwiperContext } from "../../context/swiperContext";
import { OffCanvas } from "./offCanvas";
import { useLanguage } from "../../context/languageContext";

const Layout = () => {
  const { goToSlide } = useSwiperContext();
  const navLinkTarget = [
    "Customers",
    "Expertises",
    "Software",
    "Integrations",
    "Cloud",
    "Approach",
    "X-Developers",
  ];
  return (
    <header className={styles.header}>
      {/* logo */}
      <div className={styles.navbar}>
        <div className={styles.nav_box} onClick={() => goToSlide(0)}>
          <img src="./media/logo-chiaro.svg" />
        </div>
        {/* nav link */}
        <div className={styles.nav_box}>
          {navLinkTarget.map((target, index) => (
            <div
              key={"nav" + index}
              onClick={() => goToSlide(target, index + 1)}
              className={styles.nav_link}
            >
              {target}
            </div>
          ))}
        </div>
        <div className={styles.nav_box}>
          <OffCanvas target={navLinkTarget} s />
        </div>
        <LanguageSwitcher />
      </div>
    </header>
  );
};

export default Layout;

const LanguageSwitcher = () => {
  const { language, changeLanguage } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={styles.dropdown}>
      {" "}
      {/* Utilizza la classe CSS module */}
      <div
        className={`${styles.nav_link} ${styles.dropdownToggle}`}
        type="button"
        onClick={handleToggleDropdown}
      >
        {language == "IT" ? "IT" : "EN"}
      </div>
      <div className={`${styles.dropdownMenu} ${isOpen ? styles.show : ""}`}>
        {" "}
        {/* Utilizza la classe CSS module */}
        <a
          className={styles.dropdownItem}
          href="#"
          onClick={() => {
            changeLanguage("IT");
            handleToggleDropdown();
          }}
        >
          <img src="./loghi/italian.png" />
          IT
        </a>
        <a
          className={styles.dropdownItem}
          href="#"
          onClick={() => {
            changeLanguage("EN");
            handleToggleDropdown();
          }}
        >
          <img src="./loghi/english.png" />
          EN
        </a>
        {/* Aggiungi altre lingue se necessario */}
      </div>
    </div>
  );
};
