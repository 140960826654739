import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/layout/layout";
import Home from "./routes/it/home/home";
import NoPage from "./routes/it/noPage/noPage";
import { SwiperProvider } from "./context/swiperContext";
import { useEffect, useState } from "react";
import { LoadingPage } from "./routes/it/loadingPage/loadingPage";
import { EntraceAnimation } from "./components/animation/entranceAnimation";
import { LanguageProvider } from "./context/languageContext";

export default function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simula un ritardo di caricamento
    setTimeout(() => {
      setIsLoading(false); // Imposta isLoading su false dopo 2 secondi
    }, 1500);
  }, []);

  return (
    <SwiperProvider>
      <LanguageProvider>
        {isLoading ? (
          <LoadingPage />
        ) : (
          <EntraceAnimation animationType={"fadeIn"}>
            <BrowserRouter>
              <Layout />
              <Routes>
                <Route path="/" element={<Home />} />
                {/* <Route path="blogs" element={<Blogs />} /> */}
                {/* <Route path="contact" element={<Contact />} /> */}
                <Route path="/*" element={<NoPage />} />
              </Routes>
            </BrowserRouter>
          </EntraceAnimation>
        )}
      </LanguageProvider>
    </SwiperProvider>
  );
}
