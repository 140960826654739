import { useSwiperContext } from "../../context/swiperContext";
import styles from "./footer.module.css";
export const Footer = () => {
  const { goToSlide } = useSwiperContext();
  return (
    <footer class={styles.footer}>
      <div class={[styles.container]}>
      <div class={styles.footer_col}>
          <h4>Contacts</h4>
          <ul>
            <li>
              <a href="mailto:info@livexenon.it" style={{textTransform: "none"}}>
                Commercial: <br/>
                info@livexenon.it
              </a>
            </li>
            <li>
              <a href="mailto:a.dellarte@livexenon.it" style={{textTransform: "none"}}>
                Applications: <br/>
                a.dellarte@livexenon.it
              </a>
            </li>
            <li>
              <a href="tel:+393914849981">
                Tel: <br/>
                +39 3914849981
              </a>
            </li>
          </ul>
        </div>
        <div class={styles.footer_col}>
          <h4>company</h4>
          <ul>
            <li>
              <a href="#" onClick={() => goToSlide("Customers", 1)}>
                Customers
              </a>
            </li>
            <li>
              <a href="#" onClick={() => goToSlide("Expertises", 2)}>
                Expertises
              </a>
            </li>
            <li>
              <a href="#" onClick={() => goToSlide("Approach", 6)}>
                Approach
              </a>
            </li>
            <li>
              <a href="#" onClick={() => goToSlide("X-Developers", 7)}>
                X-Developers
              </a>
            </li>
          </ul>
        </div>
        <div class={styles.footer_col}>
          <h4>Services</h4>
          <ul>
            <li>
              <a href="#" onClick={() => goToSlide("Software Development", 3)}>
                Software Development
              </a>
            </li>
            <li>
              <a href="#" onClick={() => goToSlide("System Integrations", 4)}>
                System Integrations
              </a>
            </li>
            <li>
              <a href="#" onClick={() => goToSlide("Cloud Architectures", 5)}>
                Cloud Architectures
              </a>
            </li>
          </ul>
        </div>
        <div class={styles.footer_col}>
          <h4>follow us</h4>
          <div class={styles.social_links}>
            <a href="https://www.facebook.com/livexenon/?locale=it_IT">
              <img src="./media/facebook.svg" />
            </a>
            <a href="https://www.instagram.com/livexenonweb/?hl=it">
              <img src="./media/instagram.svg" />
            </a>
            <a href="https://it.linkedin.com/company/livexenon">
              <img src="./media/linkedin.svg" />
            </a>
            <a href="https://twitter.com/livexenonweb">
              <img src="./media/x_twitter.svg" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
