import { TypeFormModal } from "../../typeFromModal/typeFormModal";
import styles from "./people.module.css";
import { motion } from "framer-motion";
import { useLanguage } from "../../../context/languageContext";
export const People = ({ slide }) => {
  const { currentTextJson } = useLanguage();
  const imgVariants = {
    offscreen: {
      x: 0,
      y: 0,
      opacity: 1,
    },
    onscreen: {
      x: -400,
      opacity: 0,
      transition: {
        type: "spring",
        bounce: 0.1,
        duration: 0.7,
        delay: 0.7,
      },
    },
  };
  const textVariants = {
    offscreen: {
      x: 200,
      opacity: 0,
    },
    onscreen: {
      x: -0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.1,
        duration: 0.7,
        delay: 1,
      },
    },
  };
  return (
    <>
      {" "}
      <section id="people">
        <div class={styles.people_section}>
          <motion.div
            initial="offscreen"
            animate={slide.isActive ? "onscreen" : "offscreen"}
            variants={imgVariants}
          >
            <img src="./media/PEOPLE-text.webp" />
          </motion.div>
          <motion.div
            className={styles.people_text_box}
            initial="offscreen"
            animate={slide.isActive ? "onscreen" : "offscreen"}
            variants={textVariants}
          >
            <h2>{currentTextJson?.people?.title}</h2>
            <p>{currentTextJson?.people?.subtitle}</p>
            <TypeFormModal text={currentTextJson?.people?.textButton} />
          </motion.div>
        </div>
      </section>
    </>
  );
};
