import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import styles from "./solution.module.css";
import { Autoplay, Pagination } from "swiper/modules";
import { useEffect, useState } from "react";
import { HeadingHeroAnimation } from "../../animation/headingHeroAnimation";
import { TypeFormModal } from "../../typeFromModal/typeFormModal";
import { useLanguage } from "../../../context/languageContext";
export const Solution = ({ slide }) => {
  const { currentTextJson } = useLanguage();
  const [slidesPerView, setSlidesPerView] = useState(3);

  const hand = () => {
    if (window.innerWidth < 770) {
      setSlidesPerView(1);
    } else {
      setSlidesPerView(window.innerWidth < 1600 ? 2 : 3);
    }
    return slidesPerView;
  };

  useEffect(() => {
    hand();
  }, [slide?.isActive]);
  return (
    <section id="solution" class={styles.solution_container}>
      <div class={styles.solution_box}>
        <div class={styles.title_box}>
          <h3>
            {currentTextJson?.solution?.title.map((title, index) => (
              <HeadingHeroAnimation
                words={title.word}
                count={title.count}
                hasDot={title.hasDot}
                colored={index == 0 ? true : false}
              />
            ))}
            <br />
          </h3>
        </div>
        <br />
        <br />
        {slide?.isActive && (
          <Swiper
            slidesPerView={"auto"}
            centeredSlides={true}
            spaceBetween={30}
            modules={[Pagination, Autoplay]}
            loop={true}
            autoplay={{ delay: 1000, disableOnInteraction: false }}
            className="solution_swiper"
          >
            {/* Your slides */}
            {currentTextJson?.solution?.sliderTextArray?.map((text, index) => (
              <SwiperSlide key={index} className={styles.scrolling_text}>
                {(isActive) => (
                  <span className={isActive.isActive ? styles.highlight : ""}>
                    {text}
                  </span>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        <br />
        <br />
        <div class={styles.button_box}>
          <TypeFormModal
            dark={true}
            text={currentTextJson?.solution?.textButton}
          />
        </div>
      </div>
    </section>
  );
};
