import { PopupButton } from "@typeform/embed-react";

import styles from "./typeFromModal.module.css";
export const TypeFormModal = ({ text, dark }) => {
  return (
    <PopupButton
      id="foA72eoC"
      className={!dark ? styles.openButton_light : styles.openButton_dark}
    >
      {text}
    </PopupButton>
  );
};
