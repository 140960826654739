import * as React from "react";
import { motion, useCycle } from "framer-motion";
import "./offcanvas.css";
import { useDimensions } from "./useDimension";
import { useSwiperContext } from "../../context/swiperContext";

const sidebar = {
  open: {
    translateX: 0,
    transition: {
      duration: 0.3,
    },
  },

  closed: {
    translateX: 1200,
    transition: {
      delay: 0.1,
      duration: 0.3,
    },
  },
};
export const OffCanvas = ({ target }) => {
  const { swiper } = useSwiperContext();

  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = React.useRef(null);
  const { height } = useDimensions(containerRef);

  const goToSlide = (index) => {
    if (swiper) {
      swiper.slideTo(index);
      if (isOpen) {
        toggleOpen();
      }
    }
  };
  return (
    <motion.div
      className="offCanvas_nav"
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
      style={isOpen ? { width: "100%" } : ""}
    >
      <motion.div className="background" variants={sidebar} />
      <Navigation isOpen={isOpen} target={target} goToSlide={goToSlide} />
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.div>
  );
};
const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.5,
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      duration: 0.3,
      y: { stiffness: 1000 },
    },
  },
};

const MenuItem = ({ target, goToSlide, index }) => {
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      {/* <div className="icon-placeholder" /> */}
      <div
        className="text-placeholder nav_link"
        onClick={() => goToSlide(index + 1)}
      >
        {target}
      </div>
    </motion.li>
  );
};
const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);

const MenuToggle = ({ toggle }) => (
  <div className="offCanvas_button" onClick={toggle}>
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
      />
    </svg>
  </div>
);
const variantsNavigation = {
  open: {
    y: 0,
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    y: 1000,
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const Navigation = ({ isOpen, target, goToSlide }) => (
  <motion.ul
    className={isOpen ? "show" : "closed"}
    variants={variantsNavigation}
  >
    {target.map((target, index) => (
      <MenuItem
        key={index}
        index={index}
        target={target}
        goToSlide={goToSlide}
      />
    ))}
  </motion.ul>
);
