import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";

// import required modules
import { Mousewheel, Pagination } from "swiper/modules";
import { Hero } from "../hero/hero";
import { Pillar } from "../pillars/pillar";
import { useSwiperContext } from "../../../context/swiperContext";
import { Solution } from "../solution/solution";
import { CompanyCore } from "../CompanyCore/companyCore";
import { People } from "../people/people";
import { Footer } from "../../layout/footer";
import { Cta } from "../Cta/cta";
import { ServiceSlide } from "../ServicesComponent/serviceSlide";
import { useLanguage } from "../../../context/languageContext";

export const SwiperHome = () => {
  const { currentTextJson } = useLanguage();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  // Usa il riferimento al controller di Swiper per gestire la transizione delle slide
  const { setSwiperInstance } = useSwiperContext();

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    // Aggiungi un listener per gestire gli eventi di ridimensionamento della finestra
    window.addEventListener("resize", handleResize);
    // Pulisci l'event listener quando il componente viene smontato
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Swiper
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        height={windowHeight}
        direction={"vertical"}
        effect={"slide"}
        slidesPerView={1}
        mousewheel={true}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        modules={[Mousewheel, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide className="home_slide">
          {(slide) => <Hero isActive={slide} />}
        </SwiperSlide>
        <SwiperSlide className="home_slide">
          {(slide) => <Pillar isActive={slide} />}
        </SwiperSlide>
        <SwiperSlide className="home_slide">
          {(slide) => <Solution slide={slide} />}
        </SwiperSlide>
        {currentTextJson?.ServiceSlides.map((dataSlide) => (
          <SwiperSlide className="home_slide">
            {(slide) => <ServiceSlide dataSlide={dataSlide} slide={slide} />}
          </SwiperSlide>
        ))}
        <SwiperSlide className="home_slide">
          {(slide) => <CompanyCore slide={slide} />}
        </SwiperSlide>
        <SwiperSlide className="home_slide">
          {(slide) => <People slide={slide} />}
        </SwiperSlide>
        <SwiperSlide className="home_slide">
          {(slide) => <Cta slide={slide} />}
        </SwiperSlide>
        <SwiperSlide className="home_slide">
          {(slide) => <Footer slide={slide} />}
        </SwiperSlide>
      </Swiper>
    </>
  );
};
