import { SwiperHome } from "../../../components/home/fullPageSwiper/swiperHome";

const Home = () => {
  return (
    <>
      <SwiperHome />
    </>
  );
};

export default Home;
