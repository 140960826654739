import { useState } from "react";
import "./accordion.css";
import styles from "./serviceSlide.module.css";
import { EntraceAnimation } from "../../animation/entranceAnimation";
import { HeadingHeroAnimation } from "../../animation/headingHeroAnimation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

export const ServiceSlide = ({ slide, dataSlide }) => {
  return (
    <section className={styles.softdev_slide}>
      <div className={styles.container_service}>
        <div className={styles.left_box}>
          <h3>
            {slide?.isActive && (
              <>
                {dataSlide?.title.map((title) => (
                  <HeadingHeroAnimation
                    words={title.word}
                    count={title.count}
                    hasDot={title.hasDot}
                  />
                ))}
              </>
            )}
          </h3>
          <div className={styles.loghi_container}>
            {slide?.isActive && (
              <Swiper
                slidesPerView={3}
                centeredSlides={true}
                spaceBetween={10}
                className={styles.logo_swiper}
                modules={[Autoplay]}
                loop={true}
                autoplay={{ delay: 1000, disableOnInteraction: false }}
              >
                {dataSlide.img.map((src, index) => (
                  <SwiperSlide key={index} className={styles.logo_slide}>
                    <img src={"./loghi/" + src} alt={src} />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
          {slide?.isActive && (
            <div className="content_box">
              <EntraceAnimation animationType={"slideInLeft"}>
                <p>{dataSlide.p}</p>
              </EntraceAnimation>
            </div>
          )}
        </div>
        <div className={styles.right_box}>
          {slide?.isActive && (
            <EntraceAnimation animationType={"slideInRight"}>
              {console.log(window.innerHeight, window.innerWidth)}
              {(window.innerHeight > 700) & (window.innerWidth > 400) ? (
                <SimpleList accordionData={dataSlide.accordionData} />
              ) : (
                <Accordion accordionData={dataSlide.accordionData} />
              )}
            </EntraceAnimation>
          )}
        </div>
      </div>
    </section>
  );
};

const AccordionItem = ({ id, title, content, activeId, setActiveId }) => {
  const isActive = id === activeId;

  const toggleAccordion = () => {
    setActiveId(isActive ? null : id);
  };

  return (
    <div>
      <button
        className={`accordion ${isActive ? "active" : ""}`}
        onClick={toggleAccordion}
      >
        <span>0{id}.</span>
        {title}{" "}
        <img
          className={isActive ? "up" : "down"}
          src="./media/chevron-down.svg"
          alt=""
        />
      </button>
      <div className={`panel ${isActive ? "active" : ""}`}>
        <p>{content}</p>
      </div>
    </div>
  );
};

const Accordion = ({ accordionData }) => {
  const [activeId, setActiveId] = useState(1);

  return (
    <div className="accordion_box">
      {accordionData.map((item) => (
        <AccordionItem
          key={item.id}
          id={item.id}
          title={item.title}
          content={item.content}
          activeId={activeId}
          setActiveId={setActiveId}
        />
      ))}
    </div>
  );
};
const SimpleList = ({ accordionData }) => {
  return (
    <div className="accordion_box">
      {accordionData.map((item) => (
        <div>
          <button className={`accordion  active  `}>
            <span>0{item.id}.</span>
            {item.title}
          </button>
          <div className={`panel active `}>
            <p>{item.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
