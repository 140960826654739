import style from "./cta.module.css";
import { motion } from "framer-motion";
import { HeadingHeroAnimation } from "../../animation/headingHeroAnimation";
import { TypeFormModal } from "../../typeFromModal/typeFormModal";
import { EntraceAnimation } from "../../animation/entranceAnimation";
import { useLanguage } from "../../../context/languageContext";
export const Cta = ({ slide }) => {
  const { currentTextJson } = useLanguage();
  const mobile = window.innerWidth > 650 ? true : false;

  const ctaVariants = {
    offscreen: mobile
      ? {
          scale: 1,
          borderRadius: "0%",
        }
      : { scale: 1, borderRadius: "0px", marginTop: " 75px" },
    onscreen: mobile
      ? {
          marginTop: " 75px",
          scale: 0.9,
          borderRadius: "20px",
          transition: {
            type: "spring",
            bounce: 0.1,
            duration: 1.5,
            delay: 1,
          },
        }
      : { scale: 1, borderRadius: "0px" },
  };
  return (
    <section className={style.cta_container}>
      <motion.div
        className={style.cta_box}
        initial="offscreen"
        animate={slide.isActive ? "onscreen" : "offscreen"}
        variants={ctaVariants}
      >
        <div className={style.cta_text}>
          {slide.isActive ? (
            <h3>
              <HeadingHeroAnimation
                words={currentTextJson?.cta?.title[0].word}
                count={currentTextJson?.cta?.title[0].count}
              />
              <p>
                <EntraceAnimation animationType={"fadeIn"} delay={1.3}>
                  <span>{currentTextJson?.cta?.title[1].word}</span>
                </EntraceAnimation>
              </p>
            </h3>
          ) : (
            <></>
          )}
          <TypeFormModal text={currentTextJson?.cta?.textButton} />
        </div>
        <div className={style.cta_img}>
          <img src="./media/footer-cta-transparent.webp" alt="" />
        </div>
      </motion.div>
    </section>
  );
};
