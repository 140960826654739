import styles from "./companyCore.module.css";
import { useLanguage } from "../../../context/languageContext";
export const CompanyCore = () => {
  const { currentTextJson } = useLanguage();

  return (
    <section className={styles.section_row}>
      <div class={styles.row}>
        {currentTextJson?.companyCore?.columnsTitleSub.map((col, index) => (
          <div key={index} class={styles.col}>
            <img src={col.img} alt={col.img} />
            <h3>{col.title}</h3>
            <p>{col.subtitle}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
