import { motion } from "framer-motion";
import { HeadingHeroAnimation } from "../../animation/headingHeroAnimation";
import styles from "./pillar.module.css";
import { useLanguage } from "../../../context/languageContext";
export const Pillar = ({ isActive }) => {
  const mobile = window.innerWidth > 650 ? true : false;
  const { currentTextJson } = useLanguage();
  const pillarVariants = {
    offscreen: mobile
      ? {
          scale: 1,
          borderRadius: "0%",
        }
      : { scale: 1, borderRadius: "0px" },
    onscreen: mobile
      ? {
          scale: 0.8,
          borderRadius: "20px",
          transition: {
            type: "spring",
            bounce: 0.1,
            duration: 1.5,
            delay: 1,
          },
        }
      : { scale: 1, borderRadius: "0px" },
  };
  return (
    <section className={styles.pillar_slide}>
      <motion.div
        id="#pillar"
        className={styles.container}
        initial="offscreen"
        animate={isActive.isActive ? "onscreen" : "offscreen"}
        variants={pillarVariants}
      >
        <div class={styles.box}>
          {isActive.isActive ? (
            <h3>
              <HeadingHeroAnimation
                words={currentTextJson?.pillar?.title[0]?.word}
                count={currentTextJson?.pillar?.title[0]?.count}
                hasDot={currentTextJson?.pillar?.title[0]?.hasDot}
              />
              <p>
                <HeadingHeroAnimation
                  words={currentTextJson?.pillar?.title[1]?.word}
                  count={currentTextJson?.pillar?.title[1]?.count}
                  hasDot={currentTextJson?.pillar?.title[1]?.hasDot}
                />
              </p>
            </h3>
          ) : (
            <></>
          )}
        </div>
        <div className={styles.pillar_img_box}>
          <img src="./media/sectors.svg" />
        </div>
      </motion.div>
    </section>
  );
};
