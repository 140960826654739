import React, { createContext, useState, useContext } from "react";
import texts from "../json/texts.json";
// Definisci il Context
const LanguageContext = createContext();

// Definisci un componente provider per il Context
export const LanguageProvider = ({ children }) => {
  var userLang = navigator.language || navigator.userLanguage;
  const [language, setLanguage] = useState(userLang == "it-IT" ? "IT" : "EN");

  const currentTextJson = texts[language];
  // Funzione per cambiare la lingua
  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (
    <LanguageContext.Provider
      value={{ currentTextJson, language, changeLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

// Hook personalizzato per accedere al Context
export const useLanguage = () => {
  return useContext(LanguageContext);
};
