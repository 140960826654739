import "./loadingPage.css";
export const LoadingPage = () => {
  return (
    <div className="container_loading">
      <div class="box">
        <img width={120} src="./media/logo-chiaro.svg" />
        <div class="b b1"></div>
        <div class="b b2"></div>
        <div class="b b3"></div>
        <div class="b b4"></div>
      </div>
    </div>
  );
};
