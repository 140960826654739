import React from "react";
import { easeInOut, motion } from "framer-motion";

export const EntraceAnimation = ({ animationType, children, delay }) => {
  // Definisci le diverse animazioni in base al parametro `animationType`
  const calcDelay = delay ? delay : 0.2;
  const animations = {
    fadeIn: {
      opacity: 1,
      transition: {
        delay: calcDelay,
        duration: 0.3,
        ease: easeInOut,
      },
    },
    slideInTop: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.2,
        duration: 0.3,
        ease: easeInOut,
      },
    },
    slideInBottom: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 1,
        duration: 0.3,
        ease: easeInOut,
      },
    },
    slideInLeft: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.2,
        duration: 0.3,
        ease: easeInOut,
      },
    },
    slideInRight: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.2,
        duration: 0.3,
        ease: easeInOut,
      },
    },
    popIn: {
      scale: 1,
      opacity: 1,
      transition: {
        delay: 0.2,
        duration: 0.3,
        ease: easeInOut,
      },
    },
  };
  const initials = {
    fadeIn: { opacity: 0 },
    slideInLeft: { opacity: 0, x: -1000 },
    slideInRight: { opacity: 0, x: 1000 },
    slideInBottom: { opacity: 0, y: -1000 },
    slideInTop: { opacity: 0, y: 1000 },
    popIn: { opacity: 0, scale: 0 },
  };

  const animation = animations[animationType] || animations.fadeIn;
  const initial = initials[animationType] || initials.fadeIn;

  return (
    <motion.div initial={initial} animate={animation}>
      {children}
    </motion.div>
  );
};
